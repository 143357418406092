<template>

  <!-- Section Record View -->
  <j-page
    :title="section.name"
    :subtitle="section.volume_name"
    :loading="loading"
    fill
    id="section-record-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Form Actions -->
      <j-actions
        v-if="editMode"
        :record="section"
        @cancel="handleCancel"
        @save="handleSave"
      />

      <!-- Edit Section Button -->
      <j-button
        v-else-if="canEdit"
        type="icon"
        help="Edit this section"
        icon="edit"
        @action="handleEdit"
      />

      <!-- Section Directory Button -->
      <j-button
        v-if="viewMode"
        type="icon"
        help="Section Directory"
        icon="list"
        @action="handleDirectrory"
      />

    </template>

    <!-- Left - Section Details     -->
    <template #left>

      <!-- Section Tile -->
      <SectionTile :section="section" />

      <!-- Section Editors -->
      <SectionEditors />

    </template>

    <!-- Right - View / Edit -->
    <template #right>
      
      <!-- Router View -->
      <router-view />

    </template>
  </j-page>
</template>

<script>

// Components
import SectionEditors from '@/components/Sections/SectionEditors'
import SectionTile from '@/components/widgets/sections/SectionTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('sections')

export default {
  name: 'SectionRecordView',
  // -------------
  // Components ==
  // -------------
  components: {
    SectionEditors,
    SectionTile
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchSection(vm.slug)
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.section.name || '...'
    }
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    mode: {
      type: String,
      default: 'view'
    },
    slug: {
      type: String,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'editors',
      'section',
      'articles'
    ]),
    ...mapGetters([
      'loading',
      'show',
      'canEdit'
    ]),
    viewMode () { return this.$route.name == 'sections-view' },
    editMode () { return this.$route.name == 'sections-edit' }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchSection',
      'fetchArticles',
      'saveSection'
    ]),
    // Handlers
    handleEdit() {
      this.$router.push({ name: 'sections-edit' })
    },
    handleCancel () {
      this.$router.push({ name: 'sections-view' })
    },
    handleSave () {
      this.saveSection()
        .then(() => this.$router.push({ name: 'sections-view' }))
    },
    handleDirectrory() {
      this.$router.push({ name: 'sections-directory' })
    },
    handleSearch(query) {
      this.fetchArticles({
        slug: this.slug,
        query
      })
    },
    handlePaginate(page) {
      this.fetchArticles({
        slug: this.slug,
        query: this.articles.get('query'),
        page
      })
    },
  }
}
</script>
