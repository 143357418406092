<template>

  <!-- Section Editors -->
  <div class="section-editors">

    <!-- Heading -->
    <j-heading
      v-if="editors.length"
      heading="Section Editors"
    >

      <!-- Heading Actions -->
      <template #actions>

        <!-- Add New Section Editor Button -->
        <j-button
          v-if="canUpdateEditors && !addingEditor"
          label="Add Editor"
          type="header"
          icon="plus"
          class="float-right"
          @action="handleShowAddEditor"
        />

      </template>
    </j-heading>

    <!-- Add Editor Form -->
    <j-form
      v-if="addingEditor"
      model="sections.section"
    >
      <j-control
        cols="9"
        name="editor"
      />
      <v-col cols="3">
        <j-button
          label="Add"
          class="my-4"
          block
          @action="handleAddEditor"
        />
      </v-col>
    </j-form>

    <!-- Editor Listing -->
    <StaffCredit
      v-for="editor in editors.getModels()"
      :key="editor.name"
      :contact="editor"
      :card-title="null"
      class="show-pointer mb-4"
      @portrait="handleViewEditor(editor.slug)"
    >

      <!-- Staff Credit Actions -->
      <template #actions>

        <!-- Remove Editor -->
        <j-icon
          v-if="canUpdateEditors && !addingEditor"
          icon="times"
          class="delete-icon float-right"
          @click="handleRemoveEditor(editor.slug)"
        />

      </template>
    </StaffCredit>
  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('sections')

// Components
import StaffCredit from '@/components/Staff/StaffCredit'

export default {
  name: 'SectionEditors',
  // -------------
  // Components ==
  // -------------
  components: {
    StaffCredit
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'addingEditor',
      'editors',
      'section'
    ]),
    // Policy
    canUpdateEditors () {
      return this.$user.isAdmin
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'addEditor',
      'removeEditor',
    ]),
    ...mapMutations([
      'SHOW_EDITOR_FIELD'
    ]),
    // Handlers
    handleViewEditor (slug) {
      this.$router.push({ name: 'contributor-view', params: { slug }})
    },
    handleShowAddEditor () {
      this.SHOW_EDITOR_FIELD()
    },
    handleAddEditor () {
      this.addEditor()
    },
    handleRemoveEditor (slug) {
      this.removeEditor(slug)
    }
  }
}
</script>

<style lang="scss">

.delete-icon {
  cursor: pointer;
}

</style>
