<template>

  <!-- Section Tile -->
  <j-tile
    :badge-url="section.badge_src"
    :kicker="section.volume_name"
    :kicker-color="section.highlight_color"
    :headline="section.name"
    :description="section.description"
    class="section-tile"
  >

    <!-- Actions Passthrough -->
    <template #actions><slot name="actions" /></template>

    <!-- Footer Passthrough -->
    <template #footer><slot name="footer" /></template>

  </j-tile>
</template>

<script>

export default {
  name: 'SectionTile',
  // -------------
  // Properties ==
  // -------------
  props: {
    section: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
